import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ElementPortableText from 'src/components/ElementPortableText/ElementPortableText';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';
import { ApplicationFormSection } from 'src/components/WebFormApplication/ApplicationFormSection';
import WebFormContext from 'src/components/WebFormContext/WebFormContext';

function JobOpeningPage({ location, data }) {
  const jobOpening = {
    title: data.jobOpening.title ?? '[title]',
    description: data.jobOpening.description ?? '[description]',
    body: data.jobOpening._rawBody ?? [],
  };

  const seo = {
    title: jobOpening.pageSeo?.title ?? jobOpening.title,
    description: jobOpening.pageSeo?.description ?? jobOpening.description,
    image: jobOpening.pageSeo?.image?.asset?.url,
    location,
  };

  return (
    <PageLayout>
      <PageSEO {...seo} />
      <WebFormContext>
        <Container fluid className="bg-shapes-node-page px-0 pt-7">
          {jobOpening && (
            <Container className="py-6">
              <Row className="pt-6">
                <Col xs={12} lg={{ span: 5, offset: 2 }}>
                  <h1 className="h2">{jobOpening.title}</h1>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Row>
                    <Col className="post-editor text-muted ck-editor-text py-6" xs={12}>
                      <ElementPortableText content={jobOpening.body} variant={['post', 'narrow']} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          )}
          <ApplicationFormSection />
        </Container>
      </WebFormContext>
    </PageLayout>
  );
}

export const query = graphql`
  query ($id: String!) {
    jobOpening: sanityJobOpening(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      pageSeo {
        ...getPageSeo
      }
      _rawBody
      description
    }
  }
`;

JobOpeningPage.propTypes = {
  data: PropTypes.shape({
    jobOpening: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default JobOpeningPage;
